// We want to load Stripe.js loaded on all pages (https://github.com/stripe/stripe-js#ensuring-stripejs-is-available-everywhere)
// This uses "importing as a side-effect": https://github.com/stripe/stripe-js#import-as-a-side-effect
import "@stripe/stripe-js";

import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { EVENT_SOURCE } from "@outschool/experiments-shared";
import { LookupIPProvider } from "@outschool/iplookup-client";
import {
  BASE_LOCALE,
  I18nLocale,
  I18nType,
  SUPPORTED_LOCALES,
  TranslationsProvider,
  createUseMachineTranslationHook,
  createUseTranslationHook
} from "@outschool/localization";
import { Component } from "@outschool/ownership-areas";
import { loginPath } from "@outschool/routes";
import {
  AnalyticsProvider,
  PageContextProvider,
  isBotRequest
} from "@outschool/ui-analytics";
import { ApolloProvider } from "@outschool/ui-apollo";
import { LoginFlowProvider, TokenProvider } from "@outschool/ui-auth";
import { VideoPlayerProvider } from "@outschool/ui-components-shared";
import {
  MobileSearchBarProvider,
  PageAlertsProvider,
  SnackbarProvider,
  TopNoticeProvider,
  useTopNoticeContext
} from "@outschool/ui-components-website";
import { ExperimentsServiceProvider } from "@outschool/ui-experiments";
import {
  ClientVersionProvider,
  RateLimitConfig,
  useClientVersion,
  useGraphqlClient
} from "@outschool/ui-gql";
import {
  GlobalStyle,
  LegacyThemeProvider,
  Loading
} from "@outschool/ui-legacy-component-library";
import {
  IsBotProvider,
  NavigationProvider,
  Redirect
} from "@outschool/ui-utils";
import { History } from "history";
import React from "react";
import {
  Redirect as ReactRouterRedirect,
  Route,
  Router,
  Switch
} from "react-router-dom";

import {
  EXPERIMENTS_SERVICE_URL,
  EXPERIMENT_ASSIGNMENTS,
  EXPERIMENT_ASSIGNMENTS_MAX_REQ_TIME_MS,
  FEATURE_FLAGS,
  IPLOOKUP_API_BASE_URL,
  IPLOOKUP_API_KEY,
  IPLOOKUP_INFO,
  OUTSCHOOL_ENV,
  SEARCH_LISTINGS_RATE_LIMIT_PER_MINUTE,
  clientVersion,
  isDevelopment,
  isPrerenderRequest,
  isProduction,
  isStaging,
  isTest
} from "../../shared/Env";
import useExperimentOverridesEnabled from "../hooks/useExperimentOverridesEnabled";
import { useIsTranslating } from "../hooks/useIsTranslating";
import usePageInfo from "../hooks/usePageInfo";
import { useWebsiteNavigation } from "../hooks/useWebsiteNavigation";
import clientSchema from "../lib/clientSchema";
import { FeatureFlagProvider } from "../lib/FeatureFlags";
import { setGlobalUserForSentry } from "../lib/sentry";
import { AttributionProvider } from "../providers/AttributionProvider";
import { LocalizationProvider } from "../providers/LocalizationProvider";
import AppContainer from "../routes/AppContainer";
import ROUTES from "../routes/index";
import NotFoundPage from "../routes/NotFoundPage";
import AppState, { RouteOnEnterFunction } from "../stores/AppState";
import { AppStateProvider, useAppState } from "../stores/AppStateProvider";
import Link from "./Link";
import SignupWithGoogleOneTap from "./SignupWithGoogleOneTap";
import SimpleErrorBoundary from "./SimpleErrorBoundary";
import VideoPlayer from "./VideoPlayer";

const GRAPHQL_RATE_LIMITS: RateLimitConfig = {};
if (SEARCH_LISTINGS_RATE_LIMIT_PER_MINUTE) {
  GRAPHQL_RATE_LIMITS.SearchListings = {
    operationsPerMinute: SEARCH_LISTINGS_RATE_LIMIT_PER_MINUTE
  };
}

const emotionCache = createCache({
  key: "outschool",
  // We have to disable "speedy" mode for Prerender
  // See: https://github.com/prerender/prerender/issues/522
  speedy: (isProduction || isStaging) && !isPrerenderRequest
});

type RouteMapProps = {
  appState: any;
};

const RouteMap = React.memo(({ appState }: RouteMapProps) => (
  <Switch>
    {ROUTES.map(routeDefinition => {
      const { path, redirect } = routeDefinition;

      const originalPath = Array.isArray(path) ? path[0] : path;
      if (redirect) {
        return (
          <ReactRouterRedirect
            key={originalPath}
            exact
            from={originalPath}
            to={redirect}
          />
        );
      }

      const {
        component: Component,
        onEnter,
        exact = true,
        roles = []
      } = routeDefinition;

      if (!Component) {
        throw new Error("CRITICAL ERROR: Route Component is undefined");
      }

      return (
        <Route
          key={originalPath}
          exact={exact}
          path={path}
          render={renderProps => {
            const { location } = renderProps;

            const redirectToPath =
              onEnter &&
              appState[onEnter] &&
              (appState[onEnter] as RouteOnEnterFunction)(location, roles);
            if (redirectToPath?.startsWith("http")) {
              // Some redirects are complete URLs outside of react-router, go directly to those.
              /* eslint-disable-next-line no-restricted-syntax */
              window.location.replace(redirectToPath);
              return null;
            }

            return redirectToPath ? (
              <Redirect to={redirectToPath} />
            ) : (
              <Component {...renderProps} />
            );
          }}
        />
      );
    })}
    <Route component={NotFoundPage} />
  </Switch>
));

const OPTIONAL_ROUTE_LOCALE_PATH = `/:locale(${SUPPORTED_LOCALES.join(
  "|"
)})?/*`;

type LocalizedRouterProps = React.PropsWithChildren<{
  history: History;
  userSelectedLocale?: I18nLocale;
}>;

const LocalizedRouter = ({
  history,
  userSelectedLocale,
  children
}: LocalizedRouterProps) => {
  const appState = useAppState();

  return (
    <Router history={history}>
      <NavigationProvider
        linkComponent={Link}
        useNavigation={useWebsiteNavigation}
      >
        <LoginFlowProvider setHandleLoggedIn={appState.setHandleLoggedIn}>
          <Route path={OPTIONAL_ROUTE_LOCALE_PATH} exact={false}>
            {({ location }) => {
              // react-router location will not have a basename (locale) in the pathname.
              // If this pathname does contain one, it means the URL contains two, duplicate, locales.
              // This occurs where we are programatically building a path using the window.location (such as login)
              // Remove this locale so we can properly redirect.
              const { pathname, search } = location;
              const [basePathname] = pathname.split("/").filter(Boolean);
              if (
                userSelectedLocale &&
                userSelectedLocale !== BASE_LOCALE &&
                basePathname === userSelectedLocale
              ) {
                const redirectPath = `${pathname.slice(
                  `/${basePathname}`.length
                )}${search}`;
                return <Redirect to={redirectPath} />;
              }

              return (
                <AppContainer>
                  <LocalizationProvider userSelectedLocale={userSelectedLocale}>
                    <SnackbarProvider>{children}</SnackbarProvider>
                  </LocalizationProvider>
                </AppContainer>
              );
            }}
          </Route>
        </LoginFlowProvider>
      </NavigationProvider>
    </Router>
  );
};

const useTranslation = createUseTranslationHook(useIsTranslating);

type AppProps = {
  appState: AppState;
  userSelectedLocale?: I18nLocale;
  i18n: I18nType;
  history: History;
};

function WrappedInTokenContext({
  appState,
  userSelectedLocale,
  history
}: Omit<AppProps, "i18n">) {
  const isExperimentOverridesEnabled = useExperimentOverridesEnabled();
  const expectedClientVersionRef = useClientVersion();

  const apolloClient = useGraphqlClient({
    initialClientVersion: clientVersion,
    expectedClientVersionRef,
    userSelectedLocale,
    rateLimits: GRAPHQL_RATE_LIMITS,
    typeDefs: clientSchema,
    logMissingRefreshToken: FEATURE_FLAGS.LOG_MISSING_REFRESH_TOKEN
  });

  return (
    <ExperimentsServiceProvider
      initialExperimentAssignments={EXPERIMENT_ASSIGNMENTS}
      // @ts-ignore TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      experimentsServiceUrl={EXPERIMENTS_SERVICE_URL}
      isTest={isTest}
      isProduction={isProduction}
      isExperimentOverridesEnabled={isExperimentOverridesEnabled}
      osEnv={OUTSCHOOL_ENV}
      timeoutMs={EXPERIMENT_ASSIGNMENTS_MAX_REQ_TIME_MS}
      eventSource={EVENT_SOURCE.BROWSER}
    >
      <MobileSearchBarProvider>
        <ApolloProvider client={apolloClient}>
          <AppStateProvider appState={appState}>
            <FeatureFlagProvider>
              <LookupIPProvider
                // @ts-ignore TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
                iplookupApiBaseUrl={IPLOOKUP_API_BASE_URL}
                // @ts-ignore TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                iplookupApiKey={IPLOOKUP_API_KEY}
                skip={isPrerenderRequest}
                bakedLocation={IPLOOKUP_INFO}
              >
                <VideoPlayerProvider videoPlayerComponent={VideoPlayer}>
                  <React.Suspense fallback={<Loading delay={1000} />}>
                    <LocalizedRouter
                      history={history}
                      userSelectedLocale={userSelectedLocale}
                    >
                      <RouteMap appState={appState} />
                      <SignupWithGoogleOneTap />
                    </LocalizedRouter>
                  </React.Suspense>
                </VideoPlayerProvider>
              </LookupIPProvider>
            </FeatureFlagProvider>
          </AppStateProvider>
        </ApolloProvider>
      </MobileSearchBarProvider>
    </ExperimentsServiceProvider>
  );
}

export function TranslationsInterstitial({
  appState,
  userSelectedLocale,
  i18n,
  history
}: AppProps) {
  const { setTopNotice } = useTopNoticeContext();

  const useMachineTranslation = React.useMemo(() => {
    return createUseMachineTranslationHook({
      useIsTranslating,
      onTranslated: () => setTopNotice(""),
      onError: ({ error, translatedErrorMessage }) => {
        setTopNotice(translatedErrorMessage);
        OsPlatform.captureError(error, {
          component: Component.International,
          tags: {
            isGoogleTranslate: true
          }
        });
      }
    });
  }, [setTopNotice]);

  const onLogout = React.useCallback(() => {
    setGlobalUserForSentry(null);
    window.Intercom?.("shutdown");

    // Full page reload on logout()
    //eslint-disable-next-line no-restricted-syntax
    window.location.assign(
      userSelectedLocale === BASE_LOCALE
        ? loginPath(true)
        : "/" + userSelectedLocale + loginPath(true)
    );
  }, [userSelectedLocale]);

  return (
    <TranslationsProvider
      i18n={i18n}
      useTranslation={useTranslation}
      useMachineTranslation={useMachineTranslation}
    >
      <SimpleErrorBoundary>
        <AttributionProvider>
          <TokenProvider
            isTest={isTest}
            isDevelopment={isDevelopment}
            onLogout={onLogout}
          >
            <WrappedInTokenContext
              appState={appState}
              userSelectedLocale={userSelectedLocale}
              history={history}
            />
          </TokenProvider>
        </AttributionProvider>
      </SimpleErrorBoundary>
    </TranslationsProvider>
  );
}

export default ({ appState, userSelectedLocale, i18n, history }: AppProps) => {
  const pageInfo = usePageInfo(history, userSelectedLocale);
  const [isBot] = React.useState(() => isBotRequest());
  return (
    <PageContextProvider pageInfo={pageInfo}>
      <ClientVersionProvider initialClientVersion={clientVersion}>
        <LookupIPProvider
          // @ts-ignore TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
          iplookupApiBaseUrl={IPLOOKUP_API_BASE_URL}
          // @ts-ignore TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          iplookupApiKey={IPLOOKUP_API_KEY}
          skip={isPrerenderRequest}
          bakedLocation={IPLOOKUP_INFO}
        >
          <IsBotProvider value={isPrerenderRequest || isBot}>
            <AnalyticsProvider>
              <CacheProvider value={emotionCache}>
                <LegacyThemeProvider>
                  <PageAlertsProvider>
                    <TopNoticeProvider>
                      <TranslationsInterstitial
                        appState={appState}
                        userSelectedLocale={userSelectedLocale}
                        i18n={i18n}
                        history={history}
                      />
                    </TopNoticeProvider>
                  </PageAlertsProvider>
                </LegacyThemeProvider>
                <GlobalStyle />
              </CacheProvider>
            </AnalyticsProvider>
          </IsBotProvider>
        </LookupIPProvider>
      </ClientVersionProvider>
    </PageContextProvider>
  );
};
